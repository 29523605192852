@use '@/components/colors.scss';

.main {
    & :global {
        table.counts {
            width: 100%;
            th {
                font-weight: normal;
                padding: 0.2rem 0;
                padding-left: 1rem;
                padding-right: 2rem;
                white-space: nowrap;
            }
            td {
                padding: 0.2rem 0;
                padding-right: 0.5rem;
                text-align: right;
                white-space: nowrap;
            }

            .subTotal {
                th {
                    padding-left: 0;
                }

                th,
                td {
                    font-weight: 600;
                }
            }

            tbody:not(:first-child)::before {
                content: '';
                display: block;
                height: 1rem;
            }
        }

        .simpleBar span {
            &.outage {
                background-color: #406426;
                border: 1px solid #406426;
            }

            &.validEstimate {
                background-color: colors.$activeBlue;
                border: 1px solid colors.$activeBlue;
            }

            &.noEstimate {
                background-color: #ff6600;
                border: 1px solid #ff6600;
            }

            &.expiredEstimate {
                background-color: #800000;
                border: 1px solid #800000;
            }

            &.nonOutage {
                background-color: #00917c;
                border: 1px solid #00917c;
            }

            &.created {
                background-color: #eecccc;
                border: 1px solid #660000;
            }

            &.resolved {
                background-color: #406426;
                border: 1px solid #406426;
            }
        }
    }
}

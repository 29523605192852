.content {
    min-height: 15rem;

    header {
        margin-bottom: 1.5rem;
    }

    footer {
        margin-top: 1.5rem;
    }

    :global(.row) {
        margin-left: 0;
        margin-right: 0;
    }
}

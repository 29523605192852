.main {
    :global(.unmapped) {
        label {
            background-color: orange;
        }
    }
}

.idHeader {
    font-size: 0.8rem;
    border: none;
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
}

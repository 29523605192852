.modal {
    :global {
        .modal-dialog {
            width: 75vw;
            max-width: 75vw;

            @media (max-width: 767px) {
                width: 90vw;
                max-width: 90vw;
            }

            .modal-content {
                height: 75vh;

                @media (max-width: 767px) {
                    height: 90vh;
                }

                .modal-body {
                    overflow-y: auto;
                }
            }

            div.modal-header {
                background-color: #bbb;
                .modal-title {
                    color: black;
                    font-size: 1.2rem;
                }
                border-bottom: 1px solid #666;
            }
        }
    }
}

.button {
    border: none;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
}

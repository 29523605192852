.main {
    padding-top: 0;
    min-height: 10rem;

    :global {
        table.differences {
            margin-top: 0;

            thead tr th {
                border-bottom: 1px solid #777;
            }

            th {
                font-weight: 600;
                width: 15rem;
            }

            width: 100%;

            td {
                vertical-align: top;
            }
        }
    }
}

@use 'sass:color';
@use '../../../components/colors';

.main {
    .inputColumn {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        input {
            padding-left: 3rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            border-radius: 0.25rem;
            font-size: 0.9rem;

            &::placeholder {
                color: color.adjust(colors.$activeBlue, $lightness: 10%, $space: hsl);
            }
        }

        button {
            padding: 0 1rem;
            height: 1.8rem;
        }

        .iconButton {
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparent;
            color: colors.$grey3;
            border: none;

            &:disabled {
                color: colors.$grey3;
            }

            &:not(:disabled) {
                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;
                    color: colors.$brandBlue;
                }
            }
        }
    }
}

.selectionList {
    position: absolute;
    width: 100%;
    z-index: 9999;

    .content {
        background-color: #fff;
        border: 1px solid colors.$grey3;
        line-height: 1.75rem;
        margin-top: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        h4 {
            font-size: 1rem;
            font-weight: 600;
            border-bottom: 1px solid colors.$grey3;
            line-height: 2rem;
        }

        b {
            font-weight: 700;
        }

        table {
            td {
                padding-right: 1rem;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

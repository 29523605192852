@use '@/components/colors';

.main {
    width: 100%;

    & > thead tr {
        td,
        th {
            border-bottom: 1px solid #666;

            padding-top: 1rem;
            padding-bottom: 1rem;
            vertical-align: top;

            cursor: pointer;

            &.status {
                width: 1rem;
                padding: 0;

                &.active {
                    background-color: #550000;
                    color: #fff;
                }

                &.resolved.recent {
                    background-color: #006600;
                    color: #fff;
                }
            }

            tbody {
                td,
                th {
                    border-bottom: none;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                td {
                    font-weight: normal;
                }
            }
        }
    }

    & > tbody {
        td,
        th {
            padding: 0.5rem 1rem;
            background-color: #f3f3f3;
        }
    }

    :global {
        h2 {
            font-weight: 600;
            font-size: 0.9rem;
            text-align: center;
        }

        .noOrders {
            text-align: center;
            color: #73767b;
        }

        .orderSelection {
            width: 15rem;
        }

        .clickable {
            cursor: pointer;
        }

        .popover {
            width: 75%;
            max-width: 75%;

            .popover-body {
                padding: 0.5rem;

                table {
                    td,
                    th {
                        background-color: transparent;
                    }
                }
            }
            .popover-header {
                padding: 0.5rem;
            }
        }

        .nav-pills {
            .nav-link.active,
            .show > .nav-link {
                color: #fff;
                font-weight: 600;
            }

            .nav-link {
                border-radius: 0;
                color: colors.$activeBlue;

                &,
                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .orderSubheader {
            font-size: 0.75rem;
            font-weight: normal;
            color: colors.$grey3;

            span {
                display: inline-block;
            }

            .type {
                width: 3rem;
            }

            .value {
                width: 16rem;
            }

            .detail {
            }
        }
    }
}

.orderSelection {
    :global {
        .status {
            width: 1rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.75rem;
            vertical-align: top;

            svg {
                color: rgba(255, 255, 255, 0.8);
            }

            // TODO: come up with better colors for predicted
            &.predicted {
                background: #555555;

                &.open {
                    background: #555555;
                }
            }

            &.nonOutage {
                background: #5cad5c;

                &.open {
                    background: #ad5c5c;
                }
            }

            &.outage {
                background: #006600;

                &.open {
                    background: #550000;
                }
            }
        }

        .duration {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.75rem;
            vertical-align: top;
        }

        .label {
            padding: 0.75rem;
        }
    }
}
